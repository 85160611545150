import { AvField, AvForm } from "availity-reactstrap-validation"
import Switch from "react-switch"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardSubtitle, Table } from "reactstrap"
import { CardTitle, TabContent, TabPane } from "reactstrap"
import { Col, Container, Label, Row } from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "components/Common/Breadcrumb"

// Form Editor
import FormEditors from "./Editor"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { WithContext as ReactTags } from "react-tag-input"
import "./style.css"

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

//actions
import {
  createProduct,
  getArea_lists,
  getSettings,
  getCategories,
  // getStoreList,
} from "store/actions"

import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "common/LanguageSwitch"
import DatePikerTime from "components/Common/TimePicker"
import MetaTag from "components/Common/Meta-tag"
import UseImageUploader from "components/imageUploader/imageUploader"
import { lastSpaceRemover } from "components/Common/LastSpaceRemover"

const CreateProduct = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    loading,
    createProductError,
    categoryList,
    categoryLoading,
    storeList,
    storesLoading,
    currency,
  } = useSelector(state => ({
    createProductError: state.Products.createProductError,
    loading: state.Products.loading,
    categoryList: state.Category.categories,
    categoryLoading: state.Category.loading,
    currency: state.Settings.settings.company?.defaultCurrencySymbol,
  }))

  const [selectedMulti, setselectedMulti] = useState(null)

  const optionGroup = categoryList.map(category => {
    return {
      value: category._id,
      label: category.primaryLang.name,
    }
  })

  function handleMulti(categoryData) {
    setselectedMulti(categoryData)
    const arrangedCategories = categoryData.map(a => a.value)
    setCategories(arrangedCategories)
  }

  const [tags, setTags] = useState([])

  const suggestions = categoryList.map(category => {
    return {
      id: category._id,
      text: category.primaryLang.name,
    }
  })

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  const [varients, setVarients] = useState([
    {
      value: "",
      unit: "kg",
      cost: "",
      price: "",
      offerPrice: "",
      primePrice: "",
      stock: "",
    },
  ])

  const [selectDateTimeFrom, setSelectDateTimeFrom] = useState("00:00")
  const [selectDateTimeTo, setSelectDateTimeTo] = useState("00:00")

  const [imagesPreview, setImagesPreview] = useState([])

  //baseData
  const [itemCode, setItemCode] = useState("")
  const [barcode, setBarcode] = useState("")

  const [maxOrderQuantity, setMaxOrderQuantity] = useState("")
  const [minOrderQuantity, setMinOrderQuantity] = useState("")

  const [primaryLangName, setPrimaryLangName] = useState("")
  const [primaryLangDescription, setPrimaryLangDescription] = useState("")
  const [secondaryLangName, setSecondaryLangName] = useState("")
  const [secondaryLangDescription, setSecondaryLangDescription] = useState("")
  const [primaryLangHighlights, setPrimaryLangHighlights] = useState("")
  const [secondaryLangHighlights, setSecondaryLangHighlights] = useState("")

  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])

  //labels
  const [isReturnViable, setisReturnViable] = useState(false)
  const [isDailyProduct, setIsDailyProduct] = useState(false)
  const [isSpecialOffer, setIsSpecialOffer] = useState(false)

  const [hasVarients, setHasVarients] = useState(false)

  const [activeTab, setActiveTab] = useState("1")

  const isMult = LanguageSwitch()?.isMult

  function handleValidSubmit(e, values) {
    setPrimaryLangName(lastSpaceRemover(primaryLangName))
    const product = {
      images: imagesPreview,
      itemCode: itemCode,
      barcode: barcode,
      primaryLang: {
        name: lastSpaceRemover(primaryLangName),
        description: primaryLangDescription,
        highlights: primaryLangHighlights,
      },
      secondaryLang: {
        name: secondaryLangName,
        description: secondaryLangDescription,
        highlights: secondaryLangHighlights,
      },
      keywords,
      categories,
      varients,
      hasVarients: hasVarients,

      //values
      cost: parseInt(values.cost),
      price: parseInt(values.price),
      offerPrice: parseInt(values.offerPrice),
      primePrice: parseInt(values.primePrice),
      stock: parseInt(values.stock),

      //labels
      isReturnViable: isReturnViable,
      isDailyProduct: isDailyProduct,
      isSpecialOffer: isSpecialOffer,

      maxOrderQuantity: parseInt(maxOrderQuantity),
      minOrderQuantity: parseInt(minOrderQuantity),
      availableTimeFrom: selectDateTimeFrom,
      availableTimeTo: selectDateTimeTo,
    }
    console.log(product)
    dispatch(createProduct(product, history))
  }

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getArea_lists(1))
    dispatch(getSettings())
  }, [dispatch])

  const validateName = v => {
    let name = v.target.value
    name = name.replace(/-/g, "")
    name = name.replace("/", "")
    v.target.value = name

    setPrimaryLangName(name)
  }

  return (
    <>
      <MetaTag title="Create Product" />

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Create Product" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              <Row xl="12">
                {/* baseData */}
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green">
                        Base Data
                      </CardTitle>
                      <Row>
                        <Col className="col-6 d-flex flex-wrap">
                          <div className="col-4 mb-3">
                            <AvField
                              name="itemCode"
                              label="Item Code"
                              type="text"
                              onChange={v => {
                                setItemCode(v.target.value)
                              }}
                            />
                          </div>

                          <div className="col-4 mb-3 mx-3">
                            <AvField
                              name="barcode"
                              label="Barcode"
                              type="text"
                              onChange={v => {
                                setBarcode(v.target.value)
                              }}
                            />
                          </div>

                          <div className="">
                            <Label className="mb-1">Images</Label>
                            <UseImageUploader
                              imageSize={4}
                              imagesPreview={imagesPreview}
                              setImagesPreview={setImagesPreview}
                              uploadUrl={"/product/admin/upload/image"}
                              deleteUrl={"/product/admin/delete/image"}
                            />
                          </div>
                        </Col>
                        <Col
                          className="col-6 d-flex flex-wrap "
                          style={{ flexDirection: "column" }}
                        >
                          {/* <Row className="dailyProductParent mb-2">
                            <Col className="parent_dateRange col-12">
                              <div className={"align-items-center d-flex mb-1"}>
                                <Label check for="checkbox">
                                  {" "}
                                  Daily Product :
                                </Label>
                                <Switch
                                  uncheckedIcon={<Offsymbol />}
                                  checkedIcon={<OnSymbol />}
                                  className="me-1 mb-sm-8 mx-3"
                                  onColor="#626ed4"
                                  onChange={v => {
                                    setIsDailyProduct(v)
                                  }}
                                  checked={isDailyProduct}
                                />
                              </div>
                              {isDailyProduct ? (
                                <div className="d-flex flex-wrap align-items-start justify-content-start mb-2 mt-2 timePickerWrapper">
                                  <div className="d-flex flex-column">
                                    <label>Available From </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeFrom}
                                      state={selectDateTimeFrom}
                                    />
                                    <span className="my-1 text-info">
                                      Enter time in 24 hours
                                    </span>
                                  </div>
                                  <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </div>
                                  <div className="d-flex flex-column">
                                    <label>Available To </label>
                                    <DatePikerTime
                                      setState={setSelectDateTimeTo}
                                      state={selectDateTimeTo}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <div className="align-items-center d-flex">
                              <Label check for="checkbox">
                                {" "}
                                Special Offer :
                              </Label>
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mx-3"
                                onColor="#626ed4"
                                onChange={v => {
                                  setIsSpecialOffer(v)
                                }}
                                checked={isSpecialOffer}
                              />
                            </div>
                          </Row>
                          {isSpecialOffer ? (
                            <Row>
                              <div className="col-4">
                                <AvField
                                  name="minOrderQuantity"
                                  label="Min Order Quantity"
                                  type="number"
                                  onChange={v => {
                                    setMinOrderQuantity(v.target.value)
                                  }}
                                />
                              </div>
                              <div className="col-4 mb-2 mx-3">
                                <AvField
                                  name="maxOrderQuantity"
                                  label="Max Order Quantity"
                                  type="number"
                                  onChange={v => {
                                    setMaxOrderQuantity(v.target.value)
                                  }}
                                />
                              </div>
                            </Row>
                          ) : (
                            ""
                          )} */}
                          <div className="mb-3 align-items-center d-flex">
                            <Label check for="checkbox">
                              {" "}
                              Return Viable :
                            </Label>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mx-3"
                              onColor="#626ed4"
                              onChange={v => {
                                setisReturnViable(v)
                              }}
                              checked={isReturnViable}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row xl="12">
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      {isMult && (
                        <LanguageTab
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      )}
                      <TabContent activeTab={activeTab} className="">
                        <TabPane tabId="1">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <AvField
                                    name="primaryLang.name"
                                    label="Name *"
                                    type="text"
                                    onKeyUp={v => validateName(v)}
                                    errorMessage="Invalid product name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                  />
                                </div>

                                {/* //Description */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label mt-2"
                                  >
                                    Description
                                  </Label>
                                  <Col sm="12" className="mt-2">
                                    {primaryLangDescription &&
                                    primaryLangDescription != undefined ? (
                                      <FormEditors
                                        content={primaryLangDescription}
                                        setContent={setPrimaryLangDescription}
                                      />
                                    ) : (
                                      <>
                                        <FormEditors
                                          content={primaryLangDescription}
                                          setContent={setPrimaryLangDescription}
                                        />
                                      </>
                                    )}
                                  </Col>
                                </div>

                                {/* Highlights */}
                                <div className="col-12 mb-3">
                                  <Label
                                    htmlFor="lbltype"
                                    className="form-label"
                                  >
                                    Highlights
                                  </Label>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={primaryLangHighlights}
                                      setContent={setPrimaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="2">
                          <Row>
                            <Col className="col-12 d-flex flex-wrap">
                              <Col className="col-12 d-flex flex-wrap">
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Name</CardSubtitle>
                                    <CardSubtitle>اسم</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.name"
                                    label=""
                                    type="text"
                                    onChange={v => {
                                      setSecondaryLangName(v.target.value)
                                    }}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Description</CardSubtitle>
                                    <CardSubtitle>وصف</CardSubtitle>
                                  </div>
                                  <AvField
                                    name="secondaryLang.description"
                                    label=""
                                    type="textarea"
                                    onChange={v => {
                                      setSecondaryLangDescription(
                                        v.target.value
                                      )
                                    }}
                                    rows="7"
                                  />
                                </div>

                                <div className="col-12 mb-3">
                                  <div className="d-flex justify-content-between mb-2">
                                    <CardSubtitle>Highlights</CardSubtitle>
                                    <CardSubtitle>يسلط الضوء</CardSubtitle>
                                  </div>
                                  <Col sm="12" className="mt-4">
                                    <FormEditors
                                      content={secondaryLangHighlights}
                                      setContent={setSecondaryLangHighlights}
                                    />
                                  </Col>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* classifications */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="12">
                          <Row>
                            <div className="mb-3">
                              <label className="control-label">
                                Categories
                              </label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  handleMulti(e)
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>

                            <div className="col-12 mb-3">
                              <Label htmlFor="lbltype" className="form-label">
                                Keywords
                              </Label>
                              <ReactTags
                                tags={tags}
                                placeholder="Type TEXT and enter"
                                suggestions={suggestions}
                                delimiters={delimiters}
                                handleDelete={handleDeleteTag}
                                handleAddition={handleAddition}
                                handleDrag={handleDrag}
                                handleTagClick={handleTagClick}
                                // inputFieldPosition="inline"
                                autocomplete
                                allowUnique
                                inline
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* varients */}
              <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Row className="mb-2">
                          <div className="align-items-center d-flex mb-3">
                            <Label check for="checkbox">
                              {" "}
                              Has Varients ?
                            </Label>
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mx-3"
                              onColor="#626ed4"
                              onChange={v => {
                                setHasVarients(v)
                              }}
                              checked={hasVarients}
                            />
                          </div>
                          {hasVarients && hasVarients ? (
                            <Col className="col-12 d-flex flex-wrap">
                              <Col md="12" className=" mt-2 mb-2">
                                <CardTitle className="h4 mb-4 text_deals_green d-flex align-items-center">
                                  Product varients
                                  <button
                                    className="mx-2 btn bg-light d-flex align-items-center justify-content-center"
                                    onClick={e => {
                                      e.preventDefault()
                                      setVarients(currentVarient => [
                                        ...currentVarient,
                                        {
                                          value: "",
                                          unit: "kg",
                                          cost: "",
                                          price: "",
                                          offerPrice: "",
                                          primePrice: "",
                                          stock: "",
                                        },
                                      ])
                                    }}
                                  >
                                    <i
                                      className="bx bx-plus"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </button>
                                </CardTitle>
                                <div className="table-responsive">
                                  <Table
                                    responsive
                                    striped
                                    className="table-nowrap mb-0"
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          colSpan={2}
                                          className="bg-gray text-center"
                                        >
                                          Product
                                        </th>
                                        <th
                                          colSpan={4}
                                          className="text-center bg-green"
                                        >
                                          Prices ({currency})
                                        </th>
                                        <th className="bg-yellow text-center">
                                          Stock (Count)
                                        </th>
                                        <th className="bg-lightYellow text-center">
                                          Remove
                                        </th>
                                      </tr>
                                      <tr>
                                        <th className="bg-lightGray text-center">
                                          Value
                                        </th>
                                        <th className="bg-lightGray text-center">
                                          Unit
                                        </th>

                                        <th className="bg-lightGreen">Cost</th>
                                        <th className="bg-lightGreen">Price</th>
                                        <th className="bg-lightGreen">
                                          Offer Price
                                        </th>
                                        <th className="bg-lightGreen">
                                          Prime Price
                                        </th>

                                        <th className="bg-lightYellow text-center">
                                          Stock
                                        </th>
                                      </tr>
                                    </thead>
                                    {varients?.map((s, key) => (
                                      <tbody key={key} className="editor_input">
                                        <tr>
                                          <td
                                            className="bg-lightGray text-center"
                                            style={{ width: "100px" }}
                                          >
                                            <AvField
                                              name="varient.value"
                                              type="text"
                                              value={s.value}
                                              onChange={e => {
                                                const value = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          value,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td
                                            className="bg-lightGray text-center vertical-middle"
                                            style={{ width: "100px" }}
                                          >
                                            <div className="d-flex align-items-center unit_select">
                                              <AvField
                                                name="varient.unit"
                                                type="select"
                                                value={s.unit}
                                                onChange={e => {
                                                  const unit = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            unit,
                                                          }
                                                        : x
                                                    )
                                                  )
                                                }}
                                              >
                                                {UnitsList?.map((item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </option>
                                                ))}
                                              </AvField>
                                              <i className="fas fa-angle-down mx-1"></i>
                                            </div>
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.cost"
                                              type="number"
                                              value={s.cost}
                                              onChange={e => {
                                                const cost = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          cost,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="cursor-pointer bg-lightGreen ">
                                            <AvField
                                              name="varient.price"
                                              type="number"
                                              value={s.price}
                                              onChange={e => {
                                                const price = e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          price,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.offerPrice"
                                              type="number"
                                              value={s.offerPrice}
                                              onChange={e => {
                                                const offerPrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          offerPrice,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>{" "}
                                          <td className="bg-lightGreen">
                                            <AvField
                                              name="varient.primePrice"
                                              type="number"
                                              value={s.primePrice}
                                              onChange={e => {
                                                const primePrice =
                                                  e.target.value
                                                setVarients(currentVarient =>
                                                  currentVarient.map(x =>
                                                    x === s
                                                      ? {
                                                          ...x,
                                                          primePrice,
                                                        }
                                                      : x
                                                  )
                                                )
                                              }}
                                            />
                                          </td>{" "}
                                          <td className="bg-lightYellow text-center">
                                            <div className="d-flex align-items-center justify-content-center">
                                              <AvField
                                                name="varient.stock"
                                                type="number"
                                                value={s.stock}
                                                onChange={e => {
                                                  const stock = e.target.value
                                                  setVarients(currentVarient =>
                                                    currentVarient.map(x =>
                                                      x === s
                                                        ? {
                                                            ...x,
                                                            stock,
                                                          }
                                                        : x
                                                    )
                                                  )
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <button
                                              className="mx-2 btn"
                                              onClick={e => {
                                                e.preventDefault()
                                                setVarients(currentVarient =>
                                                  currentVarient.filter(
                                                    x => x !== s
                                                  )
                                                )
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{
                                                  fontSize: "20px",
                                                }}
                                              ></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </Table>
                                </div>
                              </Col>
                            </Col>
                          ) : (
                            <Row xl="12">
                              <Col xl="12">
                                <Row>
                                  <Col
                                    className="col-12 d-flex flex-wrap justify-content-space-evenly"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="cost"
                                        label="Cost"
                                        type="number"
                                        // value={cost}
                                        // onChange={v => {
                                        //   costChange(v.target.value)
                                        // }}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="price"
                                        label="Price"
                                        type="number"
                                        // value={price}
                                        // onChange={v => {
                                        //   priceChange(v.target.value)
                                        // }}
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="offerPrice"
                                        label="Offer Price"
                                        type="number"
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="primePrice"
                                        label="Prime Price"
                                        type="number"
                                      />
                                    </div>
                                    <div className="col-2 mb-3">
                                      <AvField
                                        name="stock"
                                        label="Stock"
                                        type="number"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* Save */}
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4 text_deals_green"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Product
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateProduct

export const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

export const UnitsList = ["kg", "g", "L", "mL", "pcs", "nos", "item"]

export const sidebarDatas = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
  },
  {
    routeLink: "/stockUpdate",
    icon: "bx bx-store-alt",
    title: "Stock & Offer",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Site settings",
    isSaparator: true,
  },
  {
    routeLink: "/classifications",
    icon: "bx bxs-collection",
    title: "Categories",
  },
  {
    routeLink: "/collections",
    icon: "bx bxs-extension",
    title: "Collections",
  },
  {
    routeLink: "/groups",
    icon: "fas fa-layer-group",
    title: "Groups",
  },
  {
    routeLink: "/banners",
    icon: "bx bxs-image",
    title: "Banners",
  },

  {
    routeLink: "/coupons",
    icon: "bx bxs-coupon",
    title: "Coupon",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSaparator: true,
  },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
  },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
]
